import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useMemo, useState } from "react";
import InputColor from "react-input-color";
import {
  FormControl,
  FormGroup,
  InputLabel,
  Select,
  Slider,
  Stack,
} from "@mui/material";
import Button from "@mui/material/Button";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import * as React from "react";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Spreadm8CalcWidget from "./components/Spreadm8CalcWidget";
import WidgetConstructorOriginForm from "./WidgetConstructorOriginForm";

const DEFAULT_COMPONENT_RADIUS = 15;
const DEFAULT_BUTTON_RADIUS = 5;

const light_mode_border_color = "#D1D5DB";
const dark_mode_border_color = "#4b5563";
export let light_mode_background = "#ffffff";
export let dark_mode_background = "#1f2937";
export let light_mode_text_color = "#1f2937";
export let dark_mode_text_color = "#f9fafb";

export let dark_mode_input_background = "#374151";
export let light_mode_input_background = "#f9fafb";

export let dark_mode_button_color = "#374151";
export let light_mode_button_color = "#f9fafb";

export let light_mode_button_text_color = "#1f2937";

export let dark_mode_button_text_color = '#f9fafb';

export let border_radius = "0.5rem";

export let shadow = "none";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function WidgetConstructor() {
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [lightBGCol, setLightBGCol] = useState(light_mode_background);
    const [darkBGCol, setDarkBGCol] = useState(dark_mode_background);
    const [activeCodeSnippet, setActiveCodeSnippet] = useState("html");

    const [lightModeTextColor, setLightModeTextColor] = useState(
        light_mode_text_color
    );
    const [darkModeTextColor, setDarkModeTextColor] =
        useState(dark_mode_text_color);

    const [lightModeInputBackground, setLightModeInputBackground] = useState(
        light_mode_input_background
    );
    const [darkModeInputBackground, setDarkModeInputBackground] = useState(
        dark_mode_input_background
    );

    const [lightModeButtonColor, setLightModeButtonColor] = useState(
        light_mode_button_color
    );
    const [darkModeButtonColor, setDarkModeButtonColor] = useState(
        dark_mode_button_color
    );

    const [lightModeButtonTextColor, setLightModeButtonTextColor] = useState(
        light_mode_button_text_color
    );

    const [darkModeButtonTextColor, setDarkModeButtonTextColor] = useState(
        dark_mode_button_text_color
    );

    const [lightModeBorderColor, setLightModeBorderColor] = useState(
        light_mode_border_color
    );
    const [darkModeBorderColor, setDarkModeBorderColor] = useState(
        dark_mode_border_color
    );

    const [componentRadius, setComponentRadius] = useState(
        DEFAULT_COMPONENT_RADIUS
    );
    const [spread, setSpread] = useState(0);

    const [inputRadius, setInputRadius] = useState(DEFAULT_BUTTON_RADIUS);

    const [shadow, setShadow] = useState("md");
    // dark, light, auto -> for auto changing based on user's system.
    const [colorSchema, setColorSchema] = useState("light");
    const [opacity, setOpacity] = useState(100);
    const [showEmail, setShowEmail] = useState(true);
    const [showTimezone, setShowTimezone] = useState(false);
    const [timezone, setTimezone] = useState("Europe/London");

    const [showInterbankRate, setShowInterbankRate] = useState(false);
    const [companyName, setCompanyName] = useState("Our Results");

    const [isWidgetFullWidth, setIsWidgetFullWidth] = useState(true);
    const [width, setWidth] = useState(500);

    const htmlGeneratedCode = useMemo(() => {
        return `
<!--Beginning of Spreadm8 Widget-->
<!--Firstly, connect our scripts-->
<!--A Polyfill to make sure the calculator works in any browser-->
<script src="https://cdn.jsdelivr.net/npm/@webcomponents/webcomponentsjs@2.8.0/webcomponents-loader.min.js">
</script>
<!--Actual calculator script-->
<script type="module" defer
src=https://cdn.jsdelivr.net/gh/integritas-management/integritas-costcalculator-widget@v1.0.7/dist/lib/spreadm8-widget.min.js>
</script>
        
<!--Then use the calculator (insert in HTML)-->
<!-- Adjust the width and height of the widget here-->
<div style="height: ${showEmail ? '550px' : '445px'}; width: ${isWidgetFullWidth ? '100%' : width + 'px'}">
 <spreadm8-calc
    spread="${spread}"
    mode="${colorSchema}"
    name="${companyName}"
    width="${isWidgetFullWidth ? "100%" : width + "px"}"
    height="100%"
    light_mode_background="${lightBGCol}"
    dark_mode_background="${darkBGCol}"
    light_mode_text_color="${lightModeTextColor}"
    dark_mode_text_color="${darkModeTextColor}"
    light_mode_input_background="${lightModeInputBackground}"
    dark_mode_input_background="${darkModeInputBackground}"
    light_mode_button_color="${lightModeButtonColor}"
    dark_mode_button_color="${darkModeButtonColor}"
    border_radius="${componentRadius}px"
    input_border_radius="${inputRadius}"
    shadow="${shadow}"
    opacity="${opacity}"
    show_email_input="${showEmail}"
    show_interbank_rate="${showInterbankRate}"
    light_mode_border_color="${lightModeBorderColor}"
    dark_mode_border_color="${darkModeBorderColor}"
    light_mode_button_text_color="${lightModeButtonTextColor}"
    dark_mode_button_text_color="${darkModeButtonTextColor}"
    show_timezone="${showTimezone}"
    timezone="${timezone}"
 ></spreadm8-calc>
</div>
 <!--End of Spreadm8 Widget-->
        `;
    }, [
        spread,
        colorSchema,
        companyName,
        isWidgetFullWidth,
        width,
        lightBGCol,
        darkBGCol,
        lightModeTextColor,
        darkModeTextColor,
        lightModeInputBackground,
        darkModeInputBackground,
        lightModeButtonColor,
        darkModeButtonColor,
        componentRadius,
        inputRadius,
        shadow,
        opacity,
        showEmail,
        showInterbankRate,
        lightModeBorderColor,
        darkModeBorderColor,
        lightModeButtonTextColor,
        darkModeButtonTextColor,
        showTimezone,
        timezone
    ]);

    const reactGeneratedCode = useMemo(() => {
        return `
import React, {useEffect} from 'react';

let scriptLoadingPromise;

export default function Spreadm8CalcWidget({
                                               spread="${spread}",
                                               mode="${colorSchema}",
                                               name="${companyName}",
                                               width="${
            isWidgetFullWidth
                ? "100%"
                : width + "px"
        }",
                                               height="100%",
                                               light_mode_background="${lightBGCol}",
                                               dark_mode_background="${darkBGCol}",
                                               light_mode_text_color="${lightModeTextColor}",
                                               dark_mode_text_color="${darkModeTextColor}",
                                               light_mode_input_background="${lightModeInputBackground}",
                                               dark_mode_input_background="${darkModeInputBackground}",
                                               light_mode_button_color="${lightModeButtonColor}",
                                               dark_mode_button_color="${darkModeButtonColor}",
                                               border_radius="${componentRadius}px",
                                               input_border_radius="${inputRadius}",
                                               shadow="${shadow}",
                                               opacity="${opacity}",
                                               show_email_input="${showEmail}",
                                               show_interbank_rate="${showInterbankRate}",
                                               light_mode_border_color="${lightModeBorderColor}",
                                               dark_mode_border_color="${darkModeBorderColor}",
                                               light_mode_button_text_color = "${lightModeButtonTextColor}";
                                               dark_mode_button_text_color = "${darkModeButtonTextColor}";
                                               show_timezone = "${showTimezone}";
                                               timezone = "${timezone}";
                                           }) {
    useEffect(() => {
        if (!scriptLoadingPromise) {
            scriptLoadingPromise = loadScript();
        }

        function loadScript() {
            return new Promise((resolve) => {
                const script = document.createElement('script');
                script.src = 'https://cdn.jsdelivr.net/npm/@webcomponents/webcomponentsjs@2.8.0/webcomponents-loader.min.js';
                script.onload = () => {
                    const scriptModule = document.createElement('script');
                    scriptModule.type = 'module';
                    scriptModule.src = 'https://cdn.jsdelivr.net/gh/integritas-management/integritas-costcalculator-widget@v1.0.7/dist/lib/spreadm8-widget.min.js';
                    document.head.appendChild(scriptModule);
                    resolve();
                };

                document.head.appendChild(script);
            });
        }

    }, []);

    return (
        // Adjust the width and height of the widget here
        <div style="height: ${showEmail ? '550px' : '445px'}; width: ${isWidgetFullWidth ? '100%' : width + 'px'}">
        <spreadm8-calc
            spread={spread}
            mode={mode}
            name={name}
            width={width}
            height={height}
            light_mode_background={light_mode_background}
            dark_mode_background={dark_mode_background}
            light_mode_text_color={light_mode_text_color}
            dark_mode_text_color={dark_mode_text_color}

            light_mode_input_background={light_mode_input_background}
            dark_mode_input_background={dark_mode_input_background}

            light_mode_button_color={light_mode_button_color}
            dark_mode_button_color={dark_mode_button_color}
            border_radius={border_radius}
            input_border_radius={input_border_radius}
            shadow={shadow}
            opacity={opacity}
            show_email_input={show_email_input}
            show_interbank_rate={show_interbank_rate}
            light_mode_border_color={light_mode_border_color}
            dark_mode_border_color={dark_mode_border_color}
            light_mode_button_text_color={light_mode_button_text_color}
            dark_mode_button_text_color={dark_mode_button_text_color}
            show_timezone={show_timezone}
            timezone={timezone}
        ></spreadm8-calc>
        </div>
    );
}
`;
    }, [
        spread,
        colorSchema,
        companyName,
        isWidgetFullWidth,
        width,
        lightBGCol,
        darkBGCol,
        lightModeTextColor,
        darkModeTextColor,
        lightModeInputBackground,
        darkModeInputBackground,
        lightModeButtonColor,
        darkModeButtonColor,
        componentRadius,
        inputRadius,
        shadow,
        opacity,
        showEmail,
        showInterbankRate,
        lightModeBorderColor,
        darkModeBorderColor,
        lightModeButtonTextColor,
        darkModeButtonTextColor,
        showTimezone,
        timezone
    ]);
    return (
        <>
            <Paper
                sx={{maxWidth: 1300, margin: "auto", overflow: "hidden", p: 3, mb: 3}}
            >
                <WidgetConstructorOriginForm/>
            </Paper>
            <Paper sx={{maxWidth: 1300, margin: "auto", overflow: "hidden", p: 3}}>
                <Grid
                    container
                    spacing={2}
                    sx={{backgroundColor: "white", mt: 2, width: "100%"}}
                >
                    <Grid
                        item
                        xs={12}
                        sx={{
                            backgroundColor: "#f9fafb",
                            minHeight: "700px",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "10px",
                            ml: 3,
                        }}
                    >
                        <Box
                            sx={{
                                width: "500px",
                                height: showEmail ? "550px" : "445px",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "10px",
                            }}
                        >
                            {/*Use the Spreadm8CalcWidgetLocal with the same props for
                             the local widget adjustements & testing. */}
                            <Spreadm8CalcWidget
                                spread={spread}
                                mode={colorSchema}
                                name={companyName}
                                width={isWidgetFullWidth ? "100%" : `${width}px`}
                                height={"100%"}
                                light_mode_background={lightBGCol}
                                dark_mode_background={darkBGCol}
                                light_mode_text_color={lightModeTextColor}
                                dark_mode_text_color={darkModeTextColor}
                                light_mode_input_background={lightModeInputBackground}
                                dark_mode_input_background={darkModeInputBackground}
                                light_mode_button_color={lightModeButtonColor}
                                dark_mode_button_color={darkModeButtonColor}
                                border_radius={`${componentRadius}px`}
                                input_border_radius={inputRadius}
                                shadow={shadow}
                                opacity={opacity}
                                show_email_input={showEmail}
                                show_interbank_rate={showInterbankRate}
                                light_mode_border_color={lightModeBorderColor}
                                dark_mode_border_color={darkModeBorderColor}
                                light_mode_button_text_color={lightModeButtonTextColor}
                                dark_mode_button_text_color={darkModeButtonTextColor}
                                show_timezone={showTimezone}
                                timezone={timezone}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography
                            variant={"h6"}
                            sx={{
                                fontWeight: "bold",
                            }}
                        >
                            Embed code
                        </Typography>
                        <Box
                            sx={{
                                display: "flex",
                                gap: "1rem",
                            }}
                        >
                            <Button
                                variant={
                                    activeCodeSnippet === "html" ? "contained" : "Outlined"
                                }
                                onClick={() => setActiveCodeSnippet("html")}
                            >
                                HTML
                            </Button>

                            <Button
                                variant={
                                    activeCodeSnippet === "react" ? "contained" : "Outlined"
                                }
                                onClick={() => setActiveCodeSnippet("react")}
                            >
                                React
                            </Button>
                        </Box>

                        {activeCodeSnippet === "html" && (
                            <SyntaxHighlighter language="html" style={docco}>
                                {htmlGeneratedCode}
                            </SyntaxHighlighter>
                        )}
                        {activeCodeSnippet === "react" && (
                            <SyntaxHighlighter language="typescript" style={docco}>
                                {reactGeneratedCode}
                            </SyntaxHighlighter>
                        )}
                        <Button
                            variant={"outlined"}
                            sx={{mt: 2}}
                            onClick={() => {
                                if (activeCodeSnippet === "html") {
                                    navigator.clipboard.writeText(htmlGeneratedCode);
                                } else if (activeCodeSnippet === "react") {
                                    navigator.clipboard.writeText(reactGeneratedCode);
                                }
                            }}
                        >
                            Copy Code
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Stack spacing={2}>
                            <Typography
                                variant={"h6"}
                                sx={{
                                    fontWeight: "bold",
                                }}
                            >
                                Settings
                            </Typography>

                            <Box sx={{width: "100%"}}>
                                <Box sx={{borderBottom: 1, borderColor: "divider"}}>
                                    <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        aria-label="basic tabs example"
                                    >
                                        <Tab label="General" {...a11yProps(0)} />
                                        <Tab label="Light mode" {...a11yProps(1)} />
                                        <Tab label="Dark mode" {...a11yProps(2)} />
                                    </Tabs>
                                </Box>
                                <CustomTabPanel
                                    value={value}
                                    index={0}
                                    sx={{
                                        width: "100%",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            mb: 2,
                                            width: "100%",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                minWidth: "20%",
                                            }}
                                        >
                                            Your Spread:
                                        </Typography>
                                        <Slider
                                            size="small"
                                            defaultValue={spread}
                                            aria-label="Small"
                                            valueLabelDisplay="auto"
                                            max={2}
                                            min={0}
                                            step={0.05}
                                            onChange={(e) => setSpread(e.target.value)}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            mb: 2,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                minWidth: "20%",
                                            }}
                                        >
                                            Widget Radius:
                                        </Typography>
                                        <Slider
                                            size="small"
                                            defaultValue={componentRadius}
                                            aria-label="Small"
                                            valueLabelDisplay="auto"
                                            max={50}
                                            onChange={(e) => setComponentRadius(e.target.value)}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            mb: 2,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                minWidth: "20%",
                                            }}
                                        >
                                            Input Radius:
                                        </Typography>
                                        <Slider
                                            size="small"
                                            defaultValue={inputRadius}
                                            aria-label="Small"
                                            valueLabelDisplay="auto"
                                            max={50}
                                            onChange={(e) => setInputRadius(e.target.value)}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            mb: 2,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                minWidth: "20%",
                                            }}
                                        >
                                            Opacity:
                                        </Typography>
                                        <Slider
                                            size="small"
                                            defaultValue={opacity}
                                            aria-label="Small"
                                            valueLabelDisplay="auto"
                                            max={100}
                                            min={5}
                                            onChange={(e) => setOpacity(e.target.value)}
                                        />
                                    </Box>

                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">
                                            Shadow
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={shadow}
                                            label="Age"
                                            onChange={(e) => {
                                                setShadow(e.target.value);
                                            }}
                                        >
                                            <MenuItem value={"none"}>None</MenuItem>
                                            <MenuItem value={"sm"}>Small</MenuItem>
                                            <MenuItem value={"md"}>Medium</MenuItem>
                                            <MenuItem value={"lg"}>Large</MenuItem>
                                            <MenuItem value={"xl"}>Xl</MenuItem>
                                            <MenuItem value={"2xl"}>2Xl</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <FormControl
                                        fullWidth
                                        sx={{
                                            mt: 2,
                                        }}
                                    >
                                        <InputLabel id="shadow-select-label">
                                            Color Theme
                                        </InputLabel>
                                        <Select
                                            labelId="shadow-select-label"
                                            id="shadow-select"
                                            value={colorSchema}
                                            label="Color Theme"
                                            onChange={(e) => {
                                                setColorSchema(e.target.value);
                                            }}
                                        >
                                            <MenuItem value={"light"}>Light</MenuItem>
                                            <MenuItem value={"dark"}>Dark</MenuItem>
                                            <MenuItem value={"auto"}>Auto</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={showEmail}
                                                    value={showEmail}
                                                    onChange={(e) => {
                                                        setShowEmail(e.target.checked);
                                                    }}
                                                />
                                            }
                                            label="Show Email Input"
                                        />
                                    </FormGroup>
                                  <FormGroup>
                                    <FormControlLabel
                                        control={
                                          <Checkbox
                                              checked={showTimezone}
                                              value={showTimezone}
                                              onChange={(e) => {
                                                setShowTimezone(e.target.checked);
                                              }}
                                          />
                                        }
                                        label="Show Timezone"
                                    />
                                  </FormGroup>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      Timezone
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={timezone}
                                        label="Europe/London"
                                        onChange={(e) => {
                                          setTimezone(e.target.value);
                                        }}
                                    >
                                      <MenuItem value={"Europe/London"}>Europe/London</MenuItem>
                                      <MenuItem value={"Africa/Johannesburg"}>Africa/Johannesburg</MenuItem>
                                      <MenuItem value={"Asia/Dubai"}>Asia/Dubai</MenuItem>
                                    </Select>
                                  </FormControl>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={showInterbankRate}
                                                    value={showInterbankRate}
                                                    onChange={(e) => {
                                                        setShowInterbankRate(e.target.checked);
                                                    }}
                                                />
                                            }
                                            label="Show Interbank Exchange Rate"
                                        />
                                    </FormGroup>

                                    <TextField
                                        sx={{
                                            mt: 2,
                                        }}
                                        id="widget-company-name"
                                        label="Rendered Company Name"
                                        value={companyName}
                                        fullWidth={true}
                                        onChange={(event) => {
                                            setCompanyName(event.target.value);
                                        }}
                                    />

                                    <FormGroup
                                        sx={{
                                            mt: 2,
                                            width: "100%",
                                        }}
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={isWidgetFullWidth}
                                                    value={isWidgetFullWidth}
                                                    onChange={(e) => {
                                                        setIsWidgetFullWidth(e.target.checked);
                                                    }}
                                                />
                                            }
                                            label={
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <Typography>Full Width</Typography>
                                                    <Tooltip
                                                        title={
                                                            "When autosize is on, calculator widget uses 100% of available width of the enclosing element. It is important to set a specific width and height of parent element for widget to work properly."
                                                        }
                                                    >
                                                        <IconButton>
                                                            <QuestionMarkIcon fontSize={"small"}/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </Box>
                                            }
                                        />
                                    </FormGroup>

                                    <Grid
                                        container
                                        sx={{
                                            width: "100%",
                                            mt: 1,
                                        }}
                                        spacing={2}
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            sx={{
                                                width: "100%",
                                            }}
                                        >
                                            <TextField
                                                id="width-number"
                                                label="Width (min: 500)"
                                                type="number"
                                                fullWidth={true}
                                                disabled={isWidgetFullWidth}
                                                value={width}
                                                onChange={(e) => {
                                                    setWidth(e.target.value);
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    min: 400,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </CustomTabPanel>
                                <CustomTabPanel value={value} index={1}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            width: "100%",
                                            gap: "1rem",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                width: "40%",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    mr: 2,
                                                }}
                                            >
                                                Background Color:
                                            </Typography>
                                            <InputColor
                                                initialValue={lightBGCol}
                                                onChange={(value) => setLightBGCol(value.hex)}
                                                placement="right"
                                            />
                                        </Box>

                                        <Box
                                            sx={{
                                                display: "flex",
                                                width: "40%",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    mr: 2,
                                                }}
                                            >
                                                Text Color:
                                            </Typography>
                                            <InputColor
                                                initialValue={lightModeTextColor}
                                                onChange={(value) => setLightModeTextColor(value.hex)}
                                                placement="right"
                                            />
                                        </Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                width: "40%",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    mr: 2,
                                                }}
                                            >
                                                Input Background:
                                            </Typography>
                                            <InputColor
                                                initialValue={lightModeInputBackground}
                                                onChange={(value) =>
                                                    setLightModeInputBackground(value.hex)
                                                }
                                                placement="right"
                                            />
                                        </Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                width: "40%",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    mr: 2,
                                                }}
                                            >
                                                Input Border Color:
                                            </Typography>
                                            <InputColor
                                                initialValue={lightModeBorderColor}
                                                onChange={(value) => setLightModeBorderColor(value.hex)}
                                                placement="right"
                                            />
                                        </Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                width: "40%",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    mr: 2,
                                                }}
                                            >
                                                Button Color:
                                            </Typography>
                                            <InputColor
                                                initialValue={lightModeButtonColor}
                                                onChange={(value) => setLightModeButtonColor(value.hex)}
                                                placement="right"
                                            />
                                        </Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                width: "40%",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    mr: 2,
                                                }}
                                            >
                                                Button Text Color:
                                            </Typography>
                                            <InputColor
                                                initialValue={lightModeButtonTextColor}
                                                onChange={(value) => setLightModeButtonTextColor(value.hex)}
                                                placement="right"
                                            />
                                        </Box>
                                    </Box>
                                </CustomTabPanel>
                                <CustomTabPanel value={value} index={2}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            width: "100%",
                                            gap: "1rem",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                width: "40%",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    mr: 2,
                                                }}
                                            >
                                                Background Color:
                                            </Typography>
                                            <InputColor
                                                initialValue={darkBGCol}
                                                onChange={(value) => setDarkBGCol(value.hex)}
                                                placement="right"
                                            />
                                        </Box>

                                        <Box
                                            sx={{
                                                display: "flex",
                                                width: "40%",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    mr: 2,
                                                }}
                                            >
                                                Text Color:
                                            </Typography>
                                            <InputColor
                                                initialValue={darkModeTextColor}
                                                onChange={(value) => setDarkModeTextColor(value.hex)}
                                                placement="right"
                                            />
                                        </Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                width: "40%",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    mr: 2,
                                                }}
                                            >
                                                Input Background:
                                            </Typography>
                                            <InputColor
                                                initialValue={darkModeInputBackground}
                                                onChange={(value) =>
                                                    setDarkModeInputBackground(value.hex)
                                                }
                                                placement="right"
                                            />
                                        </Box>

                                        <Box
                                            sx={{
                                                display: "flex",
                                                width: "40%",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    mr: 2,
                                                }}
                                            >
                                                Input Border Color:
                                            </Typography>
                                            <InputColor
                                                initialValue={darkModeBorderColor}
                                                onChange={(value) => setDarkModeBorderColor(value.hex)}
                                                placement="right"
                                            />
                                        </Box>

                                        <Box
                                            sx={{
                                                display: "flex",
                                                width: "40%",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    mr: 2,
                                                }}
                                            >
                                                Button Color:
                                            </Typography>
                                            <InputColor
                                                initialValue={darkModeButtonColor}
                                                onChange={(value) => setDarkModeButtonColor(value.hex)}
                                                placement="right"
                                            />
                                        </Box>

                                        <Box
                                            sx={{
                                                display: "flex",
                                                width: "40%",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    mr: 2,
                                                }}
                                            >
                                                Button Text Color:
                                            </Typography>
                                            <InputColor
                                                initialValue={darkModeButtonTextColor}
                                                onChange={(value) => setDarkModeButtonTextColor(value.hex)}
                                                placement="right"
                                            />
                                        </Box>
                                    </Box>
                                </CustomTabPanel>
                            </Box>

                            <Button
                                variant={"outlined"}
                                sx={{mt: 2}}
                                onClick={() => {
                                    setLightBGCol(light_mode_background);
                                    setDarkBGCol(dark_mode_background);
                                    setLightModeTextColor(light_mode_text_color);
                                    setDarkModeTextColor(dark_mode_text_color);
                                    setLightModeInputBackground(light_mode_input_background);
                                    setDarkModeInputBackground(dark_mode_input_background);
                                    setLightModeButtonColor(light_mode_button_color);
                                    setDarkModeButtonColor(dark_mode_button_color);
                                    setLightModeBorderColor(light_mode_border_color);
                                    setDarkModeBorderColor(dark_mode_border_color);
                                    setComponentRadius(DEFAULT_COMPONENT_RADIUS);
                                    setInputRadius(DEFAULT_BUTTON_RADIUS);
                                    setShadow("md");
                                    setColorSchema("light");
                                    setOpacity(100);
                                    setShowEmail(true);
                                    setShowInterbankRate(false);
                                    setCompanyName("Our Results");
                                    setIsWidgetFullWidth(true);
                                    setWidth(500);
                                    setSpread(0);
                                }}
                            >
                                Reset
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}
