import { LocalizationProvider, nbNO } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import * as React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Calculator from "./Calculator";
import ErrorPage from "./error-page";
import History from "./History";
import Paperbase from "./Paperbase";
import Profile from "./Profile";
import CalculatorResult from "./CalculatorResult";
import PricingPage from "./PricingPage";
import TeamTable from "./TeamTable";
import WidgetConstructor from "./WidgetConstructor";

function getRouter(user_subscribed, isVerified, isAdmin) {
  return createBrowserRouter([
    {
      path: "/",
      element: (
        <Paperbase
          isSubscribed={user_subscribed}
          isVerified={isVerified}
          isAdmin={isAdmin}
        />
      ),
      errorElement: <ErrorPage />,
      children: [
        {
          path: "/",
          element: <Calculator />,
        },
        {
            path: "/widget",
            element: <WidgetConstructor />,
        },
        {
          path: "/history",
          element: <History />,
        },
        {
          path: "/team",
          element: <TeamTable />,
        },
        {
          path: "/result",
          element: <CalculatorResult />,
        },
      ],
    },
  ]);
}

export default function App({ user }) {
  let user_subscription = null;
  let user_role = null;
  let isUnverified = true;
  let isAdmin = false;
  let isSubscribed = false;

  console.log(user);

  user_subscription = user["https://spreadm8.com/app_metadata"].subscription;
  user_role = user["https://spreadm8.com/app_metadata"].roles;

  isUnverified = user_role === undefined;

  if (!isUnverified) {
    if (user_role[0] == "ADMIN") {
      isAdmin = true;
    } else {
      isSubscribed = true;
    }
  }

  if (typeof user_subscription !== "undefined" && user_subscription !== null) {
    isSubscribed = true;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <RouterProvider
        router={getRouter(isSubscribed, !isUnverified, isAdmin)}
      />
    </LocalizationProvider>
  );
}
