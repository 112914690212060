import TablePagination from "@mui/material/TablePagination";
import * as React from "react";
import { getConfig } from "./config";
import dayjs from "dayjs";
import { useAuth0 } from "@auth0/auth0-react";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Slide from "@mui/material/Slide";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import Box from "@mui/system/Box";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function PaginationComponent({ totalItems, updateQuotes }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const { getAccessTokenSilently } = useAuth0();

  if ({ totalItems } < 50) {
    setRowsPerPage(totalItems);
  }

  let handleChangePage = async (e, newPage) => {
    setPage(newPage);
    try {
      const accessToken = await getAccessTokenSilently();

      const { apiOrigin, audience } = getConfig();
      var url = apiOrigin + "/quote/all?page=" + (newPage + 1);

      const res = await fetch(url, {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Credentials": "true",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        mode: "cors",
      });

      const json = await res.json();
      const rates = json.data[0].quotes;
      updateQuotes(rates);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <TablePagination
      component="div"
      count={totalItems}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={[]}
    />
  );
}
