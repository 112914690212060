import React, { useEffect } from "react";

let scriptLoadingPromise;

export default function Spreadm8CalcWidget({
  spread,
  mode,
  name,
  width,
  height,
  light_mode_background,
  dark_mode_background,
  light_mode_text_color,
  dark_mode_text_color,
  light_mode_input_background,
  dark_mode_input_background,
  light_mode_button_color,
  dark_mode_button_color,
  border_radius,
  input_border_radius,
  shadow,
  opacity,
  show_email_input,
  show_interbank_rate,
  light_mode_border_color,
  dark_mode_border_color,
  light_mode_button_text_color,
  dark_mode_button_text_color,
  show_timezone,
  timezone
}) {
  useEffect(() => {
    if (!scriptLoadingPromise) {
      scriptLoadingPromise = loadScript();
    }

    function loadScript() {
      return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src =
          "https://cdn.jsdelivr.net/npm/@webcomponents/webcomponentsjs@2.8.0/webcomponents-loader.min.js";
        script.onload = () => {
          const scriptModule = document.createElement("script");
          scriptModule.type = "module";
          scriptModule.src =
            "https://cdn.jsdelivr.net/gh/integritas-management/integritas-costcalculator-widget@v1.0.7/dist/lib/spreadm8-widget.min.js";
          document.head.appendChild(scriptModule);
          resolve();
        };

        document.head.appendChild(script);
      });
    }
  }, []);

  return (
    <spreadm8-calc
      spread={spread}
      mode={mode}
      name={name}
      width={width}
      height={height}
      light_mode_background={light_mode_background}
      dark_mode_background={dark_mode_background}
      light_mode_text_color={light_mode_text_color}
      dark_mode_text_color={dark_mode_text_color}
      light_mode_input_background={light_mode_input_background}
      dark_mode_input_background={dark_mode_input_background}
      light_mode_button_color={light_mode_button_color}
      dark_mode_button_color={dark_mode_button_color}
      border_radius={border_radius}
      input_border_radius={input_border_radius}
      shadow={shadow}
      opacity={opacity}
      show_email_input={show_email_input}
      show_interbank_rate={show_interbank_rate}
      light_mode_border_color={light_mode_border_color}
      dark_mode_border_color={dark_mode_border_color}
      light_mode_button_text_color={light_mode_button_text_color}
      dark_mode_button_text_color={dark_mode_button_text_color}
      show_timezone={show_timezone}
      timezone={timezone}
    ></spreadm8-calc>
  );
}
