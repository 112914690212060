import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { Button } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "./config";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export default function TeamTableRow({ row }) {
  const [formOpen, setFormOpen] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState(null);
  const { getAccessTokenSilently } = useAuth0();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  let userVerified = "Not verified";

  if (row.email_verified === true) {
    userVerified = "Verified";
  }

  const handleClose = () => {
    setFormOpen(false);
  };

  const handleBinClick = () => {
    setFormOpen(true);
  };

  let handleDelete = async (e) => {
    e.preventDefault();

    try {
      const accessToken = await getAccessTokenSilently();

      var newForm = {
        userId: row.user_id,
      };

      const { apiOrigin, audience } = getConfig();
      var url = apiOrigin + "/deleteUser";

      const res = await fetch(url, {
        method: "DELETE",
        headers: {
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Credentials": "true",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        mode: "cors",
        body: JSON.stringify(newForm),
      });

      const result = await res.json();

      if (res.status != 200) {
        setErrMsg("ERROR: " + result);
      } else {
        setFormOpen(false);
        // refresh
        navigate(0);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <TableRow
      hover={true}
      key={row.id}
      sx={{
        backgroundColor: "white",
      }}
      large
    >
      <TableCell align="left">{row.email}</TableCell>
      <TableCell align="center">{userVerified}</TableCell>
      <TableCell align="right">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Button sx={{ borderColor: "red" }} onClick={handleBinClick}>
          <DeleteIcon />
        </Button>
      </TableCell>
      <Dialog open={formOpen} onClose={handleClose}>
        {errMsg && (
          <DialogTitle
            sx={{ color: "white", backgroundColor: "red" }}
            alignItems="right"
          >
            {errMsg}
          </DialogTitle>
        )}
        <DialogTitle>Delete user</DialogTitle>

        <DialogContent>
          <DialogContentText>
            You are about to delete the user: <b>{row.email}</b>. This will
            decrease the number of users in your subscription by 1. You can add
            this user again later. <br />
            <br />
            Click delete to confirm.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete}>Delete</Button>
        </DialogActions>
      </Dialog>
    </TableRow>
  );
}
