import HelpIcon from "@mui/icons-material/Help";
import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";
import CalculatorForm from "./CalculatorForm";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

export default function Calculator() {
  const [formOpen, setFormOpen] = React.useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
  };

  const handleOpen = () => {
    setFormOpen(true);
  };

  const handleClose = () => {
    setFormOpen(false);
  };

  return (
    <Paper sx={{ maxWidth: 936, margin: "auto", overflow: "hidden" }}>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ border: "1px solid rgba(0, 0, 0, 0.12)" }}
      >
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Grid item></Grid>
            <Grid item xs></Grid>
            <Grid item>
              <Tooltip title="Help">
                <IconButton color="inherit" onClick={handleOpen}>
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <CalculatorForm />
      <Dialog open={formOpen} onClose={handleClose}>
        <DialogTitle>Help</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Field</TableCell>
                  <TableCell>Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Bought Amount</TableCell>
                  <TableCell>The value your prospect bought </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Sold Amount</TableCell>
                  <TableCell>The value your prospect sold</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Mid-Market Exchange Rate</TableCell>
                  <TableCell>
                    We show you the historic mid-market rate{" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Prospect's Exhange Rate</TableCell>
                  <TableCell>
                    The exchange rate your prospect received{" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Your Spread (%)</TableCell>
                  <TableCell>The (%) FX Markup you choose</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Your Exhange Rate</TableCell>
                  <TableCell>
                    Your exchange rate based on your chosen Spread (%)
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Prospect's saved amount</TableCell>
                  <TableCell>
                    The GBP (£) amount your prospect saved with you
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Prospect would have paid</TableCell>
                  <TableCell>
                    The GBP (£) amount your prospect would have paid{" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Prospect's annual flow</TableCell>
                  <TableCell>Your Prospect's annual FX flow in GBP</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Prospect's annual savings</TableCell>
                  <TableCell>
                    Your Prospect's annual FX savings in GBP
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Competitor's Spread (%)</TableCell>
                  <TableCell>Your Competitor's FX Spread (%) </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Competitor's PnL</TableCell>
                  <TableCell>
                    How much your Competitor made from your Prospect
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Prospect</TableCell>
                  <TableCell>Optional Prospect Identifier</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Paper>
  );
}
