import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "./config";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";
import HistoryRow from "./HistoryRow";
import { padding } from "@mui/system";
import { useApi } from "./UseApi";
import PaginationComponent from "./PaginationComponent";

function constructQuotes(quotes) {
  var quotesParsed = JSON.parse(quotes);
  return quotesParsed.map((quote) => {
    return <HistoryRow row={quote} />;
  });
}

export default function History() {
  const [quotesState, setQuotesState] = React.useState();
  const { data: quotesResponse } = useApi("/quote/all");
  const [searchText, setSearchText] = React.useState("");
  const { getAccessTokenSilently } = useAuth0();
  const [total, setTotal] = React.useState(0);

  React.useEffect(() => {
    if (quotesResponse !== null) {
      const quotes = quotesResponse.data[0].quotes;
      setQuotesState(quotes);
      setTotal(quotesResponse.data[0].total);
    }
  }, [quotesResponse]);

  if (quotesResponse === null || quotesState === undefined) {
    return <></>;
  }

  const handleQuotesUpdate = (newQuotes) => {
    setQuotesState(newQuotes);
  };

  const handleSearchClick = async (e) => {
    e.preventDefault();
    try {
      const accessToken = await getAccessTokenSilently();

      const { apiOrigin, audience } = getConfig();
      var url = apiOrigin + "/quote/all?searchText=" + searchText;

      const res = await fetch(url, {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Credentials": "true",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        mode: "cors",
      });

      const result = await res.json();
      const quotes = result.data[0].quotes;
      setQuotesState(quotes);
      setTotal(result.data[0].total);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Paper sx={{ maxWidth: 1300, margin: "auto", overflow: "hidden" }}>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <Toolbar sx={{ backgroundColor: "white" }}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            sx={{ backgroundColor: "white" }}
          >
            <Grid item>
              <IconButton color="inherit" onClick={handleSearchClick}>
                <SearchIcon
                  color="inherit"
                  sx={{
                    display: "block",
                  }}
                />
              </IconButton>
            </Grid>
            <Grid item xs>
              <TextField
                fullWidth
                placeholder="Search by prospect"
                InputProps={{
                  disableUnderline: true,
                  sx: { fontSize: "default" },
                }}
                onChange={(e) => setSearchText(e.target.value)}
                variant="standard"
              />
            </Grid>
            <Grid item>
              <Tooltip title="Reload">
                <IconButton href="/history">
                  <RefreshIcon color="inherit" sx={{ display: "block" }} />
                </IconButton>
              </Tooltip>
            </Grid>
            <React.Fragment>
              <TableContainer
                sx={{
                  maxHeight: 500,
                }}
              >
                <Table
                  size="small"
                  sx={{
                    color: "white",
                    backgroundColor: "white",
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Created</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell align="center">Prospect</TableCell>
                      <TableCell align="center">Sold Amount</TableCell>
                      <TableCell align="center">Bought Amount</TableCell>
                      <TableCell align="center">Mid-Market Rate</TableCell>
                      <TableCell align="center">Your Exchange Rate</TableCell>
                      <TableCell align="center">
                        Prospect Saved Amount
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {constructQuotes(JSON.stringify(quotesState))}
                  </TableBody>
                </Table>
              </TableContainer>
              <PaginationComponent
                totalItems={total}
                updateQuotes={handleQuotesUpdate}
              />
            </React.Fragment>
          </Grid>
        </Toolbar>
      </AppBar>
    </Paper>
  );
}
