import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import { useApi } from "./UseApi";
import WidgetOriginRow from "./WidgetOriginRow";

function constructOriginRow(res) {
  var parsed = JSON.parse(res);
  return <WidgetOriginRow row={parsed} />;
}

export default function WidgetConstructorOriginForm() {
  const [originState, setoriginState] = React.useState();
  const { data: originResponse } = useApi("/widget/origin");

  React.useEffect(() => {
    if (originResponse !== null) {
      setoriginState(originResponse);
    } else {
      setoriginState({
        origin: "",
        email: "",
      });
    }
  }, [originResponse]);

  if (originState === null || originState === undefined) {
    return <></>;
  }

  return (
    <>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <Toolbar sx={{ backgroundColor: "white" }}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            sx={{ backgroundColor: "white" }}
          >
            <Grid item></Grid>
            <Grid item xs></Grid>
            <React.Fragment>
              <TableContainer
                sx={{
                  maxHeight: 100,
                }}
              >
                <Table
                  size="small"
                  sx={{
                    color: "white",
                    backgroundColor: "white",
                  }}
                >
                  <TableBody>
                    <br />
                    {constructOriginRow(JSON.stringify(originState))}
                  </TableBody>
                </Table>
              </TableContainer>
            </React.Fragment>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
}
