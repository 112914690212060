import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import * as React from "react";
import { Link, matchPath, useLocation } from "react-router-dom";

function useRouteMatch(patterns) {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}

export default function NavTabs({ isAdmin }) {
  const routeMatch = useRouteMatch(["/", "/history", "/team"]);
  const currentTab = routeMatch?.pattern?.path;

  return (
    <Tabs value={currentTab} textColor="inherit">
      <Tab label="Calculator" value="/" to="/" component={Link} />
      <Tab label="History" value="/history" to="/history" component={Link} />
      {isAdmin && (
        <Tab label="Team" value="/team" to="/team" component={Link} />
      )}
      {isAdmin && (
        <Tab label="Widget" value="/widget" to="/widget" component={Link} />
      )}
      {isAdmin && (
        <Tab
          label="Billing"
          value="/profile"
          target="_blank"
          to="https://billing.stripe.com/p/login/14k6sd5svgPI8nK9AA"
          component={Link}
        />
      )}
    </Tabs>
  );
}
