import React, { useState } from "react";
import App from "./App";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "./components/Loading";

export default function Login() {
  const { user, isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  if (isLoading) {
    return <Loading />;
  } else if (isAuthenticated) {
    return <App user={user} />;
  } else {
    return loginWithRedirect();
  }
}
