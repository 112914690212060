import * as React from "react";
import Button from "@mui/material/Button";

export default function VerifyEmailPage() {
  // Paste the stripe-pricing-table snippet in your React component
  return (
    <div>
      <h1>Welcome to Spreadm8</h1>
      <h3>Please verify your email to get started</h3>
      <Button
        sx={{
          borderColor: "black",
          backgroundColor: "black",
          color: "white",
          "&:hover": {
            backgroundColor: "white",
            borderColor: "black",
            color: "black",
          },
        }}
        href="/"
      >
        Click here after verifying
      </Button>
      {/* <Button onClick={window.location.reload()}></Button> */}
    </div>
  );
}
