import dayjs from "dayjs";
import { useAuth0 } from "@auth0/auth0-react";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Slide from "@mui/material/Slide";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import Box from "@mui/system/Box";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getConfig } from "./config";

const label = { inputProps: { "aria-label": "Checkbox demo" } };
const shadedColor = "#81878b";

const annual_flows = [
  {
    value: "100000",
    label: "0 to 100,000",
  },
  {
    value: "250000",
    label: "100,000 to 250,000",
  },
  {
    value: "500000",
    label: "250,000 to 500,000",
  },
  {
    value: "1000000",
    label: "500,000 to 1,000,000",
  },
  {
    value: "2000000",
    label: "1,000,000 to 2,000,000",
  },
  {
    value: "3000000",
    label: "2,000,000 to 3,000,000",
  },
  {
    value: "5000000",
    label: "3,000,000 to 5,000,000",
  },
  {
    value: "5000001",
    label: "5,000,000+",
  },
];

const timezones = [
  {
    value: "Europe/London",
    label: "Europe/London",
  },
  {
    value: "Africa/Johannesburg",
    label: "Africa/Johannesburg",
  },
  {
    value: "Asia/Dubai",
    label: "Asia/Dubai",
  }
];

const currencies = [
  {
    value: "GBP",
    label: "GBP",
  },
  {
    value: "USD",
    label: "USD",
  },
  {
    value: "EUR",
    label: "EUR",
  },
  {
    value: "JPY",
    label: "JPY",
  },
  {
    value: "CHF",
    label: "CHF",
  },
  {
    value: "CNY",
    label: "CNY",
  },
  {
    value: "NZD",
    label: "NZD",
  },
  {
    value: "SGD",
    label: "SGD",
  },
  {
    value: "INR",
    label: "INR",
  },
  {
    value: "AUD",
    label: "AUD",
  },
  {
    value: "CAD",
    label: "CAD",
  },
  {
    value: "HKD",
    label: "HKD",
  },
  {
    value: "MYR",
    label: "MYR",
  },
  {
    value: "NOK",
    label: "NOK",
  },
  {
    value: "ZAR",
    label: "ZAR",
  },
  {
    value: "RUB",
    label: "RUB",
  },
  {
    value: "SEK",
    label: "SEK",
  },
];

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

export default function CalculatorForm() {
  const [formData, setFormData] = useState({
    date: null,
    time: null,
    bought_ccy: "",
    bought_notional: null,
    sold_ccy: "",
    sold_notional: null,
    spread: "",
    prospect: "",
    prospect_annual_flow: "",
    timezone: "",
    email_user: false,
  });
  const navigate = useNavigate();
  const [accessToken, setAccessToken] = useState("");
  const { getAccessTokenSilently } = useAuth0();
  const [open, setOpen] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const { user } = useAuth0();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (msg) => {
    setErrMsg(msg);
    setOpen(true);
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const accessToken = await getAccessTokenSilently();

      var newForm = {
        date: formData.date.format("YYYY-MM-DD"),
        time: formData.time.format("HH:mm"),
        bought_ccy: formData.bought_ccy,
        bought_notional: formData.bought_notional,
        sold_ccy: formData.sold_ccy,
        sold_notional: formData.sold_notional,
        prospect: formData.prospect,
        input_spread: formData.spread,
        prospect_annual_flow: formData.prospect_annual_flow,
        timezone: formData.timezone,
        email_user: formData.email_user,
        user: user.name,
      };

      console.log(newForm);

      const { apiOrigin, audience } = getConfig();
      var url = apiOrigin + "/calculate";

      const res = await fetch(url, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Credentials": "true",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        mode: "cors",
        body: JSON.stringify(newForm),
      });

      const result = await res.json();

      if (res.status != 200) {
        {
          handleOpen(result);
        }
      } else {
        var row = await JSON.parse(JSON.stringify(result)).data[0];
        navigate("/result", { state: { row } });
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const getAccessToken = async () => {
      const token = await getAccessTokenSilently();
      setAccessToken(token);
    };

    getAccessToken();
  }, [getAccessTokenSilently]);

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "95%", height: "100%" },
      }}
      noValidate={false}
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <Snackbar
        open={open}
        onClose={handleClose}
        autoHideDuration={3000}
        TransitionComponent={SlideTransition}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity="error">ERROR: {errMsg}</Alert>
      </Snackbar>
      <Grid container spacing={2} display="flex" justifyContent="center">
        <Grid
          item
          xs={6}
          sx={{
            mt: 2,
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
            paddingLeft: 1,
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs} required>
            <DatePicker
              label="Transfer Date *"
              sx={{ mt: 2 }}
              value={formData.date}
              format="YYYY-MM-DD"
              required
              onChange={(newDate) =>
                setFormData({ ...formData, date: newDate })
              }
            />
          </LocalizationProvider>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            mt: 2,
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: 1,
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              label="Transfer Time (Europe/London) *"
              value={formData.time}
              ampm="false"
              format="HH:mm"
              required
              onChange={(newTime) =>
                setFormData({ ...formData, time: newTime })
              }
            />
          </LocalizationProvider>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
            paddingLeft: 1,
          }}
        >
          <TextField
            id="sold-currency-amount"
            label="Sold Amount"
            value={formData.sold_notional}
            onChange={(e) =>
              setFormData({ ...formData, sold_notional: e.target.value })
            }
            required
            inputProps={{
              inputMode: "numeric",
              min: 0,
              pattern: `^[1-9][0-9\.]*`,
            }}
          ></TextField>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: 1,
          }}
        >
          <TextField
            id="outlined-select-sold-currency"
            select
            label="Sold Currency"
            defaultValue={"USD"}
            required
            value={formData.sold_ccy}
            onChange={(e) =>
              setFormData({ ...formData, sold_ccy: e.target.value })
            }
          >
            {currencies.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
            paddingLeft: 1,
          }}
        >
          <TextField
            id="bought-currency-amount"
            label="Bought Amount"
            inputProps={{
              inputMode: "numeric",
              min: 0,
              pattern: `^[1-9][0-9\.]*`,
            }}
            value={formData.bought_notional}
            onChange={(e) =>
              setFormData({ ...formData, bought_notional: e.target.value })
            }
            required
          ></TextField>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: 1,
          }}
        >
          <TextField
            id="outlined-select-bought-currency"
            select
            label="Bought Currency"
            defaultValue={"GBP"}
            value={formData.bought_ccy}
            onChange={(e) =>
              setFormData({ ...formData, bought_ccy: e.target.value })
            }
            required
          >
            {currencies.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
            paddingLeft: 1,
          }}
        >
          <TextField
            inputProps={{
              inputMode: "numeric",
              min: 0,
              pattern: `[0-9\.]*`,
            }}
            id="spread"
            label="Your Spread (%)"
            value={formData.spread}
            onChange={(e) =>
              setFormData({ ...formData, spread: e.target.value })
            }
            required
          ></TextField>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: 1,
          }}
        >
          <TextField
            id="prospect"
            label="Prospect"
            value={formData.prospect}
            onChange={(e) =>
              setFormData({ ...formData, prospect: e.target.value })
            }
            inputProps={{
              inputMode: "text",
              pattern: `[a-zA-Z0-9_ ]*`,
            }}
          ></TextField>
        </Grid>
        <Grid
            item
            xs={6}
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "flex-end",
              paddingLeft: 1,
            }}
        >
          <TextField
              id="outlined-select-annual-flow"
              select
              label="Prospect Annual Flow"
              value={formData.prospect_annual_flow}
              onChange={(e) =>
                  setFormData({ ...formData, prospect_annual_flow: e.target.value })
              }
          >
            {annual_flows.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid
            item
            xs={6}
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: 1,
            }}
        >
          <TextField
              id="outlined-select-annual-flow"
              select
              label="Timezone"
              value={formData.timezone}
              onChange={(e) =>
                  setFormData({ ...formData, timezone: e.target.value })
              }
          >
            {timezones.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={9}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 1, mb: 2, ml: 2, mr: 1 }}
          >
            Calculate
          </Button>
        </Grid>
        <Grid item xs={3} alignItems="center" sx={{ mb: 1 }}>
          <FormControlLabel
            sx={{ ml: 5, alignItems: "center" }}
            control={<Checkbox />}
            label="Email result"
            display="flex"
            value={formData.email_user}
            onChange={(e) =>
              setFormData({ ...formData, email_user: !formData.email_user })
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
}
