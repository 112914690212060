import * as React from "react";

export default function PricingPage() {
  // Paste the stripe-pricing-table snippet in your React component
  return (
    <div>
      <h1>Welcome to Spreadm8</h1>
      <h3>Pick a pricing plan to get started</h3>
      <stripe-pricing-table
        pricing-table-id="prctbl_1MxDWwESa681cCQQPK150IhB"
        publishable-key="pk_live_51MxBncESa681cCQQITxLdM4sYnTUgefFw3NcBaCfGOTzvKR72uttbsjRMw8Eoz9BCrWHlSUO6OAdkZ4OvlhVwRQ300uWxS8zCH"
      ></stripe-pricing-table>
    </div>
  );
}
