import RefreshIcon from "@mui/icons-material/Refresh";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";
import { Link } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useState } from "react";

const lightColor = "rgba(255, 255, 255, 0.7)";
const shadedColor = "#81878b";

// Generate Order Data
function createData(id, header, value) {
  return { id, header, value };
}

function annualFlow(annualFlow) {
  if (annualFlow != "") {
    if (annualFlow == "5000001.0") {
      return "5,000,000.00 +";
    } else {
      return annualFlow;
    }
  }
}

function annualSavings(annualFlow, annualSavings) {
  if (annualFlow != "") {
    if (annualFlow == "5000001.0") {
      return "At least " + formatNotional(annualSavings);
    } else {
      return "Up to " + formatNotional(annualSavings);
    }
  }
}

function formatRate(rate) {
  try {
    var rateFloat = parseFloat(rate);
    return rateFloat.toFixed(4);
  } catch (error) {
    console.log(error);
    return rate;
  }
}

function formatNotional(rate) {
  try {
    var rateFloat = parseFloat(rate);
    return Number(rateFloat.toFixed(2)).toLocaleString();
  } catch (error) {
    console.log(error);
    return rate;
  }
}

function hypotheticalPaidAmount(sold_amount, saved_amount) {
  return parseFloat(sold_amount) - parseFloat(saved_amount);
}

function createDataList(row) {

  return [
    createData(0, "Created", row.created_at),
    createData(1, "Date", row.date),
    createData(1, "Timezone", row.input_date_tz),
    createData(
      2,
      "Bought Amount",
      formatNotional(row.bought_notional) + " " + row.bought_ccy
    ),
    createData(
      3,
      "Sold Amount",
      formatNotional(row.sold_notional) + " " + row.sold_ccy
    ),
    createData(4, "Mid-Market Exchange Rate", formatRate(row.mid_market_rate)),
    createData(
      5,
      "Prospect's Exhange Rate",
      formatRate(row.third_party_exchange_rate)
    ),
    createData(6, "Your Spread (%)", formatNotional(row.input_spread)),
    createData(7, "Your Exhange Rate", formatRate(row.integritas_rate)),
    createData(
      8,
      "Prospect's saved amount",
      formatNotional(row.integritas_savings) + " " + row.sold_ccy
    ),
    createData(
      9,
      "Prospect would have paid",
      formatNotional(
        hypotheticalPaidAmount(row.sold_notional, row.integritas_savings)
      ) +
        " " +
        row.sold_ccy +
        " for " +
        formatNotional(row.bought_notional) +
        " " +
        row.bought_ccy
    ),
    createData(
      11,
      "Prospect's annual flow",
      annualFlow(row.prospect_annual_flow)
    ),
    createData(
      12,
      "Prospect's annual savings",
      // TODO: this needs to be returned as a value from BE!
      annualSavings(
        row.prospect_annual_flow,
        row.integritas_savings_annual_high
      )
    ),
    createData(
      13,
      "Competitor's Spread (%)",
      formatNotional(row.third_party_spread)
    ),
    createData(14, "Competitor's PnL", formatNotional(row.third_party_profit)),
    createData(15, "Prospect", row.prospect),
  ];
}

function constructData(row) {
  try {
    if (row) {
      return (
        <>
          {createDataList(row).map((row) => {
            return (
              <TableRow key={row.id}>
                <TableCell>{row.header}</TableCell>
                <TableCell>{row.value}</TableCell>
              </TableRow>
            );
          })}
        </>
      );
    }
  } catch (error) {
    console.log("Error! " + error);
  }
}

export default function CalculatorResult() {
  const location = useLocation();
  var result = null;

  if (location.state !== null) {
    result = location.state.row;
  }

  // const result = {
  //   id: "6418ecd9c446d6a2c2979c99",
  //   error_code: 0,
  //   date: "2022-09-07 11:12:00",
  //   UTC_date: "2022-09-07 11:12:00",
  //   found_date_time: "None",
  //   sold_ccy: "USD",
  //   bought_ccy: "GBP",
  //   ccy_pair: "USD/GBP",
  //   sold_notional: 100000000.0,
  //   bought_notional: 10000.0,
  //   third_party_exchange_rate: 0.0001,
  //   mid_market_rate: 0.8743,
  //   third_party_spread: 99.99,
  //   integritas_rate: 0.873,
  //   integritas_paid_amount: 11454.97,
  //   integritas_savings: 99988545.03,
  //   integritas_savings_relative: 666.59,
  //   third_party_profit: 99988562.21,
  //   integritas_cheaper: true,
  //   created_at: "20-03-2023 23:31:35",
  // };

  return (
    <Paper sx={{ maxWidth: 1300, margin: "auto", overflow: "hidden" }}>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          backgroundColor: "white",
        }}
      >
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs>
              <TextField
                fullWidth
                placeholder="Calculator Result"
                InputProps={{
                  disableUnderline: true,
                  sx: { fontSize: "default" },
                }}
                variant="standard"
              />
            </Grid>
            <Grid item>
              <Link color={shadedColor} href="/history">
                History
              </Link>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <React.Fragment>
        <Table size="small">
          <TableBody>{constructData(result)}</TableBody>
        </Table>
      </React.Fragment>
    </Paper>
  );
}
