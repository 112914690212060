// use-api.js
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "./config";

export const useApi = (url) => {
  const { getAccessTokenSilently } = useAuth0();
  const [state, setState] = useState({
    error: null,
    loading: true,
    data: null,
  });
  const [refreshIndex, setRefreshIndex] = useState(0);

  const { apiOrigin, audience } = getConfig();
  url = apiOrigin + url;

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const res = await fetch(url, {
          headers: {
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "Access-Control-Allow-Credentials": "true",
            // Add the Authorization header to the existing headers
            Authorization: `Bearer ${accessToken}`,
          },
          mode: "cors",
        });
        setState({
          ...state,
          data: await res.json(),
          error: null,
          loading: false,
        });
      } catch (error) {
        setState({
          ...state,
          error,
          loading: false,
        });
      }
    })();
  }, []);

  return {
    ...state,
    refresh: () => setRefreshIndex(refreshIndex + 1),
  };
};
