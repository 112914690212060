import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useNavigate } from "react-router-dom";

function prospect(row) {
  if (row.prospect !== null) {
    return row.prospect;
  }
  return "";
}

function formatRate(rate) {
  try {
    var rateFloat = parseFloat(rate);
    return rateFloat.toFixed(4);
  } catch (error) {
    console.log(error);
    return rate;
  }
}

function formatNotional(rate) {
  try {
    var rateFloat = parseFloat(rate);
    return Number(rateFloat.toFixed(2)).toLocaleString();
  } catch (error) {
    console.log(error);
    return rate;
  }
}

export default function HistoryRow({ row }) {
  const navigate = useNavigate();

  function handleRowClick() {
    // navigate("/result/" + row.id);
    navigate("/result", { state: { row } });
    // navigate("/result");
  }

  return (
    <TableRow
      hover={true}
      key={row.id}
      onClick={handleRowClick}
      sx={{
        backgroundColor: "white",
      }}
    >
      <TableCell>{row.created_at}</TableCell>
      <TableCell>{row.date}</TableCell>
      <TableCell align="center">{prospect(row)}</TableCell>
      <TableCell align="center">
        {formatNotional(row.sold_notional) + " " + row.sold_ccy}
      </TableCell>
      <TableCell align="center">
        {formatNotional(row.bought_notional) + " " + row.bought_ccy}
      </TableCell>
      <TableCell align="center">{formatRate(row.mid_market_rate)}</TableCell>
      <TableCell align="center">{formatRate(row.integritas_rate)}</TableCell>
      <TableCell align="center">
        {formatNotional(row.integritas_savings)}
      </TableCell>
    </TableRow>
  );
}
