import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";
import TeamTableRow from "./TeamTableRow";
import { useApi } from "./UseApi";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "./config";
import { useNavigate } from "react-router-dom";

function constructTeam(team) {
  var teamParsed = JSON.parse(team);
  return teamParsed.map((user) => {
    return <TeamTableRow row={user} />;
  });
}

export default function History() {
  const [teamState, setteamState] = React.useState();
  const { data: teamResponse } = useApi("/team");
  const [formOpen, setFormOpen] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [formData, setFormData] = useState({
    email: "",
  });
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const handleClickOpen = () => {
    setFormOpen(true);
  };

  const handleClose = () => {
    setFormOpen(false);
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const accessToken = await getAccessTokenSilently();

      if (formData.email == "") {
        setErrMsg("ERROR: E-mail must not be empty!");
        return;
      }

      var newForm = {
        email: formData.email,
      };

      const { apiOrigin, audience } = getConfig();
      var url = apiOrigin + "/inviteUser";

      const res = await fetch(url, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Credentials": "true",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        mode: "cors",
        body: JSON.stringify(newForm),
      });

      const result = await res.json();

      if (res.status != 200) {
        setErrMsg("ERROR: " + result);
      } else {
        setFormOpen(false);
        // refresh
        navigate(0);
      }
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    if (teamResponse !== null) {
      const team = teamResponse.users;
      setteamState(team);
    }
  }, [teamResponse]);

  if (teamResponse === null || teamState === undefined) {
    return <></>;
  }

  return (
    <Paper sx={{ maxWidth: 800, margin: "auto", overflow: "hidden" }}>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <Toolbar sx={{ backgroundColor: "white" }}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            sx={{ backgroundColor: "white" }}
          >
            <Grid item></Grid>
            <Grid item xs></Grid>
            <Grid item>
              <Tooltip title="Reload">
                <Button
                  variant="contained"
                  sx={{ mt: 1 }}
                  onClick={handleClickOpen}
                >
                  Add user
                </Button>
              </Tooltip>
            </Grid>
            <React.Fragment>
              <TableContainer
                sx={{
                  maxHeight: 500,
                }}
              >
                <Table
                  size="small"
                  sx={{
                    color: "white",
                    backgroundColor: "white",
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ width: "40%" }} align="left">
                        User
                      </TableCell>
                      <TableCell align="center">Status</TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {constructTeam(JSON.stringify(teamState))}
                  </TableBody>
                </Table>
              </TableContainer>
            </React.Fragment>
          </Grid>
        </Toolbar>
      </AppBar>
      <Dialog open={formOpen} onClose={handleClose}>
        {errMsg && (
          <DialogTitle
            sx={{ color: "white", backgroundColor: "red" }}
            alignItems="right"
          >
            {errMsg}
          </DialogTitle>
        )}
        <DialogTitle>Add a new user</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the user's email address here and we will send them a
            verification and password reset e-mail. Access will be granted upon
            a password reset and email verification.
          </DialogContentText>
          <br />
          <DialogContentText sx={{ fontSize: "12px" }}>
            By clicking subscribe, you confirm that the number of seats under
            your subscription will increase by 1. If you have already reached
            the maximum number of seats allowed, you will not be able to add
            this user. Please contact support if you have any other queries.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            value={formData.email}
            type="email"
            fullWidth
            variant="standard"
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Add user</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}
