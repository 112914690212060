import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { Button } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "./config";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Grid from "@mui/material/Grid";

import EditIcon from "@mui/icons-material/Edit";
export default function WidgetOriginRow({ row }) {
  const [formOpen, setFormOpen] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState(null);
  const { getAccessTokenSilently } = useAuth0();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [domain, setDomain] = useState("");
  const [email, setEmail] = useState("");
  const [isValidDomain, setIsValidDomain] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);

  const handleDomainChange = (event) => {
    const inputDomain = event.target.value;
    setDomain(inputDomain);
    validateDomain(inputDomain);
  };

  const handleEmailChange = (event) => {
    const inputEmail = event.target.value;
    setEmail(inputEmail);
    validateEmail(inputEmail);
  };

  const validateDomain = (inputDomain) => {
    // Use a regular expression to check if the entered value is a valid domain
    const domainRegex =
      /^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9\-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$/i;
    setIsValidDomain(domainRegex.test(inputDomain));
  };

  const validateEmail = (inputEmail) => {
    // Use a regular expression to check if the entered value is a valid e-mail
    const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,7}\b/;
    setIsValidEmail(emailRegex.test(inputEmail));
  };

  const handleClose = () => {
    setFormOpen(false);
  };

  const handleBinClick = () => {
    setFormOpen(true);
  };

  let handleSubmit = async (e) => {
    e.preventDefault();

    if (!isValidDomain || domain == null || domain == "") {
      console.log("Invalid domain:", domain);
    } else if (!isValidEmail || email == null || email == "") {
      console.log("Invalid email:", email);
    } else {
      try {
        const accessToken = await getAccessTokenSilently();

        var newForm = {
          origin: domain,
          email: email,
        };

        const { apiOrigin, audience } = getConfig();
        var url = apiOrigin + "/widget/origin";

        const res = await fetch(url, {
          method: "PUT",
          headers: {
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "Access-Control-Allow-Credentials": "true",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          mode: "cors",
          body: JSON.stringify(newForm),
        });

        const result = await res.json();

        if (res.status != 200) {
          setErrMsg("ERROR: " + result["error"]);
        } else {
          setFormOpen(false);
          // refresh
          navigate(0);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <TableRow
      hover={true}
      key={row.id}
      sx={{
        backgroundColor: "white",
      }}
      large
    >
      <TableCell align="left">
        <b>Widget Origin:</b> {row.origin}
      </TableCell>
      <TableCell align="left">
        <b>Widget E-mail:</b> {row.email}
      </TableCell>
      <TableCell align="right">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Button sx={{ borderColor: "red" }} onClick={handleBinClick}>
          <EditIcon />
        </Button>
      </TableCell>
      <Dialog open={formOpen} onClose={handleClose}>
        {errMsg && (
          <DialogTitle
            sx={{ color: "white", backgroundColor: "red" }}
            alignItems="right"
          >
            {errMsg}
          </DialogTitle>
        )}
        <DialogTitle>Add/Edit Origin and E-mail</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter your website origin and the e-mail address where you
            would like widget results to be sent. Note, the origin must be a
            valid HTTPS domain. If requests are sent from a domain with the
            "www." prefix, this must be added here. Once added, the spreadm8 API
            will be able to authenticate any requests coming from the widget,
            hosted on your website.
          </DialogContentText>
          <br />
          <form onSubmit={handleSubmit}>
            <Grid container spacing={5} alignItems="center" fullwidth>
              <Grid item alignItems="left" width="45%">
                <TextField
                  label="Domain"
                  variant="outlined"
                  fullWidth
                  value={domain}
                  onChange={handleDomainChange}
                  error={!isValidDomain}
                  helperText={!isValidDomain ? "Invalid domain format" : ""}
                />
              </Grid>
              <Grid item alignItems="right" width="55%">
                <TextField
                  label="E-mail"
                  variant="outlined"
                  fullWidth
                  value={email}
                  onChange={handleEmailChange}
                  error={!isValidEmail}
                  helperText={!isValidEmail ? "Invalid e-mail format" : ""}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Update</Button>
        </DialogActions>
      </Dialog>
    </TableRow>
  );
}
